import React, { useState } from 'react';
import cn from 'clsx';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const Questionnaire = ({ showQuestionnaire, toggleQuestionnaire }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [showProduct, setShowProduct] = useState(false);
  const [onSecondStep, setOnSecondStep] = useState(false);
  const [selectedUsecase, setSelectedUsecase] = useState(null);

  const nextStepHandler = () => {
    if (selectedOption === 'websites') {
      setOnSecondStep(true);
    } else {
      setShowProduct(true);
    }
  };

  const back = () => {
    if (
      (!showProduct && selectedUsecase) ||
      (!selectedUsecase && onSecondStep)
    ) {
      setSelectedUsecase(false);
      setOnSecondStep(false);
    } else {
      setShowProduct(false);
      setSelectedOption(false);
    }
  };

  return (
    <div
      id="questionnaire-modal"
      className={cn(['contact-us-modal', { show: showQuestionnaire }])}>
      <div className="contact-us-container">
        <div className="action-buttons">
          {(onSecondStep || showProduct) && (
            <button type="button" aria-label="back" onClick={() => back()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round">
                <path d="m15 18-6-6 6-6" />
              </svg>
            </button>
          )}
          <button
            type="button"
            aria-label="close"
            onClick={() => toggleQuestionnaire()}
            style={{ marginLeft: 'auto' }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round">
              <path d="M18 6 6 18M6 6l12 12" />
            </svg>
          </button>
        </div>
        {!showProduct && !onSecondStep ? (
          <div className="feedback-type">
            <p>Let&apos;s start</p>
            <h2>What form of feedback you are looking for?</h2>
            <ul>
              <li>
                <label htmlFor="websites">
                  <input
                    id="websites"
                    type="radio"
                    value="websites"
                    name="feedback-type"
                    onChange={(e) => setSelectedOption(e.target.value)}
                  />
                  Feedback on websites/web apps
                </label>
              </li>

              <li>
                <label htmlFor="mobile-apps">
                  <input
                    id="mobile-apps"
                    type="radio"
                    value="mobile-apps"
                    name="feedback-type"
                    onChange={(e) => setSelectedOption(e.target.value)}
                  />
                  Feedback on mobile apps
                </label>
              </li>
              <li>
                <label htmlFor="end-user">
                  <input
                    id="end-user"
                    type="radio"
                    value="end-user"
                    name="feedback-type"
                    onChange={(e) => setSelectedOption(e.target.value)}
                  />
                  Feedback from customers
                </label>
              </li>
            </ul>
            <div className="button-container">
              <button
                type="button"
                className="button"
                disabled={!selectedOption}
                onClick={nextStepHandler}>
                Next
              </button>
            </div>
          </div>
        ) : (
          <div>
            {showProduct && (
              <div className="recommended-product">
                {selectedOption === 'end-user' && (
                  <>
                    <h2>We recommend using</h2>
                    <Link
                      to="/customer-feedback-tool"
                      className="single-product">
                      <img
                        src="/assets/img/customer-feedback-icon.svg"
                        alt="Customer Feedback Logo"
                      />
                      <p>Customer Feedback</p>
                    </Link>
                    <p className="subtext">
                      Handle your common customer queries/issues and collect
                      user testimonials easily with our AI powered chatbot.
                      Streamline your customer support and gather valuable
                      feedback effortlessly.
                    </p>
                  </>
                )}
                {selectedOption === 'mobile-apps' && (
                  <>
                    <h2>We recommend using</h2>
                    <Link to="/mobile-app-feedback" className="single-product">
                      <img
                        src="/assets/img/mobile-app-feedback-icon.svg"
                        alt="Mobile App Feedback Logo"
                      />
                      <p>Mobile App Feedback</p>
                    </Link>

                    <p className="subtext">
                      Our Android app allows you to report bugs directly on your
                      mobile app, all comments will be added as tickets on your
                      dashboard where you can prioritize, assign, resolve and
                      set deadlines as per your need.
                    </p>
                  </>
                )}
                {selectedUsecase === 'visual' && (
                  <>
                    <h2>We recommend using</h2>
                    <Link
                      to="/website-feedback-tool"
                      className="single-product">
                      <img
                        src="/assets/img/website-feedback-icon.svg"
                        alt="Website Feedback Tool Logo"
                      />
                      <p>Website Feedback</p>
                    </Link>
                    <p className="subtext">
                      With ruttl’s website feedback tool, you can comment
                      directly on live websites or web apps and make real time
                      edits in order to give precise feedback to your team. You
                      can collect these feedback directly on your project
                      management tools like Trello, Jira, Asana and ClickUp.
                    </p>
                  </>
                )}
                {selectedUsecase === 'visual-functional' && (
                  <>
                    <h2>We recommend using</h2>
                    <Link to="/bug-tracking-tool" className="single-product">
                      <img
                        src="/assets/img/bug-tracking-icon.svg"
                        alt="Bug Tracking Tool Logo"
                      />
                      <p>Bug Tracking Tool</p>
                    </Link>
                    <p className="subtext">
                      Install the code snippet on your website and start
                      reporting bugs in the form of comments, track and resolve
                      them in workflow with your team or alternatively integrate
                      with tools like Jira, Trello, Slack, Clickup, Slack and
                      Zapier.
                    </p>
                  </>
                )}
              </div>
            )}
            {onSecondStep && !showProduct ? (
              <div className="feedback-type">
                <h2 style={{ marginTop: '10px' }}>
                  What would be an ideal usecase for you?
                </h2>
                <ul>
                  <li>
                    <label htmlFor="visual">
                      <input
                        id="visual"
                        type="radio"
                        value="visual"
                        name="usecase-type"
                        onChange={(e) => setSelectedUsecase(e.target.value)}
                      />
                      Visual level feedback
                    </label>
                  </li>
                  <li>
                    <label htmlFor="visual-functional">
                      <input
                        id="visual-functional"
                        type="radio"
                        value="visual-functional"
                        name="usecase-type"
                        onChange={(e) => setSelectedUsecase(e.target.value)}
                      />
                      Visual and functional level feedback
                    </label>
                  </li>
                </ul>
                <div className="button-container">
                  <button
                    type="button"
                    className="button"
                    disabled={!selectedUsecase}
                    onClick={() => setShowProduct(true)}>
                    Proceed
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

Questionnaire.propTypes = {
  toggleQuestionnaire: PropTypes.func,
  showQuestionnaire: PropTypes.bool,
};
export default Questionnaire;
